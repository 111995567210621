$white: #FFFFFF;
$text: #0A144A;

$bg-blue: #e6f1fc;
$bg-blue-light: #f4f5f7;
$bg-green: #C2EDF5;
$bg-yellow: #f7faf4;

$neutral: #f7f9fb;
$neutral-0: #F9FBFF;
$neutral-1: #F0F3F8;
$neutral-2: #D0D8E0;
$neutral-3: #BBCCDC;
$neutral-4: #B0BECB;
$neutral-5: #617D97;
$neutral-6: #334E67;
$neutral-7: #243953;
$neutral-8: #112B43;

$blue: #007BFF;
$blue-medium: #025F9D;
$blue-dark: #014572;
$blue-navy: #3538c8;
$blue-navy-light: #3395ff;
$blue-navy-dark: #092064;
$blue-border: #2f2ffd;

$green: #1DB9B4;
$green-light: #18b7bd;
$green-medium: #149C97;
$green-dark: #0C7470;

$turquoise: #47efe2;
$turquoise-light: #00dbf5;
$turquoise-medium: #32e9e8;

$yellow: #FEB73F;
$yellow-light: #ffe900;
$yellow-medium: #ffba06;
$yellow-dark: #ffc421;

$salmon: #ff9769;
$orange: #F6A51D;
$orange-medium: #ff5116;

$gray: #e6e7ec;
$salmon: #f8e6dd;
$brown: #5C4919;






