@import '../../../../scss/globals';


.button {
    font-size: 16px;
    font-weight: 600;
    color: $text;
    border-radius: 4px;
    height: 60px;
    width: 190px;
    margin: 0 auto;
    transition: all .3s;
    @include box-shadow(light);
    @include media(tablet) {
        font-size: 21px;
        &:hover {box-shadow: 0 10px 30px 0 rgba(10, 20, 74, 0.2);}
        &:active, &:focus {@include box-shadow(light);}
    }

    &.white {
        background-image: linear-gradient(to right, $bg-blue, $neutral);
        @include media(tablet) {
            &:active,
            &:focus {
                background-image: linear-gradient(to right, $neutral-2, $neutral-2);
            }
        }
    }

    &.light {
        background-image: linear-gradient(14deg, $turquoise 24%, $turquoise-medium 81%);
        @include media(tablet) {
            &:active,
            &:focus {background-image: linear-gradient($green-medium, $green-medium);}
        }
    }

    &.dark {
        color: $white;
        @include bg-gradient(dark-blue);
        @include media(tablet) {
            &:active,
            &:focus {background-image: linear-gradient(to right, $text, $text);}
        }
    }

}