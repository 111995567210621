@import '../../../../scss/globals';


.recruiter-card {
    position: relative;
    background-color: $white;
    border-radius: 10px;
    max-width: 445px;
    @include box-shadow(blue);
    margin: 0 auto $s-l;
    @include media(screen) {
        margin: 0;
        height: 235px;
    }

    &:first-of-type {
        .recruiter-card-img {
            top: -18px;
            height: 129px;
            width: 105px;
            @include media(screen) {
                width: 124px;
                height: 150px;
                top: -22px;
                left: 1px;
            }
        }
    }

    &:last-of-type {
        .recruiter-card-img {
            top: 0;
            height: 131px;
            width: 98px;
            @include media(screen) {
                width: 110px;
                height: 152px;
            }
        }
    }

    &-wrapper {
        position: relative;
        padding: $s-m $s-l $s-m 104px;
        display: flex;
        flex-direction: column;
        height: 112px;
        border-bottom: 1px solid rgba($text, .25);
        @include media(screen) {
            height: 130px;
            padding: $s-m $s-l $s-m 124px;
        }

        p:first-of-type {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: $s-xs;
            @include media(screen) {
                font-size: 28px;
            }
        }

        p:last-of-type {
            font-family: $f-title;
            font-weight: 600;
            color: rgba(10, 20, 74, 0.5);
            margin-bottom: $s-s;
            @include media(screen) {
                font-size: 20px;
                margin-bottom: $s-ss;
            }
        }
    }


    &-img {
        position: absolute;
        left: 0;
        @include media(screen) {
            height: 145px;
            width: 110px;
        }
    }

    &-contact {
        display: flex;
        align-items: baseline;

        a, img {
            height: 18px;
            opacity: 0.5;
            @include media(screen) {height: 21px;}
            &:hover {opacity: 0.8;}
            &:active {opacity: 1;}

            &:nth-child(2) {
                margin: 0 $s-l;
                a, img {@include media(screen) {height: 18px;}}
            }
        }

    }


    &-quote {
        font-size: 14px;
        color: rgba($text, .7);
        font-family: $f-title;
        padding: $s-m $s-l $s-l;
        @include media(tablet) {
            padding: 10px $s-xl 20px;
            line-height: 1.3;
        }
        @include media(desktop) {font-size: 16px;}
        @include media(screen) {padding: $s-mm $s-xl $s-l;}
    }
}