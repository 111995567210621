@import '../../../../scss/globals';


.video {
    position: relative;
    margin: 0 auto $s-ll;
    max-width: 1100px;
    width: 100%;
    padding: 0 $s-l 67px;
    background-image: url("../../../../img/life-at-tmh/shapes/video-bg.svg");
    background-position: bottom center;
    background-repeat: no-repeat;

    @include media(tablet) {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @include media(desktop) {
        padding: 100px 0;
        background-image: url("../../../../img/life-at-tmh/shapes/video-bg-dk-min.png");
        background-position: bottom center;
        background-size: contain;
        height: 744px;
        &:before {
            content: '';
            width: 176px;
            height: 35px;
            background-image: url("../../../../img/life-at-tmh/shapes/dots-dk.svg");
            mix-blend-mode: color-dodge;
            position: absolute;
            top: 9px;
            left: 128px;
        }
    }


    &-wrapper {
        margin: 0 auto;
        height: 224px;
        max-width: 820px;
        @media (min-width: 560px) { height: 270px; }
        @media (min-width: 680px) { height: 332px; }
        @include media(tablet) { height: 405px; }
        @include media(desktop) {
            height: 600px;
            margin-top: -30px;
        }
    }
}