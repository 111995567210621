.wrapper {
    padding: $s-l;
    max-width: 1332px;
    margin: 0 auto;

    @include media(smartphone) {
        padding: $s-l $s-xl;
    }

}

.fixed {
    position: fixed;
    overflow: hidden;
    @include media(tablet) {
        position: relative;
        overflow: visible;
    }
}


h1, h2, h3, h4, .title {
    font-size: 26px;
    line-height: 34px;
    font-family: $f-title;
    margin-bottom: $s-m !important;
    font-weight: 600;
    color: $text;
    @include media(screen) {
        font-size: 44px;
        line-height: 48px;
        margin-bottom: 32px !important;
    }
}


.subtitle {
    font-size: 21px;
    color: rgba($text, .8);
    margin-bottom: $s-ll;
    font-weight: 500;
    @include media(screen) {
        font-size: 26px;
        margin-bottom: $s-xxl;
    }
}



.paragraph {
    font-size: 16px;
    line-height: 24px;
    color: rgba($text, .7);
    font-weight: 400;
    margin-bottom: $s-m;
    @include media(screen) {
        font-size: 22px;
        line-height: 35px;
    }
}