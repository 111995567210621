@import '../../../../scss/globals';

.tag-grid {
    display: flex;
    flex-wrap: wrap;
    width: 315px;
    max-width: 315px;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translateX(60%);
    @include media(smartphone) {
        width: 420px;
        max-width: 420px;
        transform: translateX(61%);
    }

    @include media(tablet) {
        justify-content: flex-end;
        transform: translateX(0);
        max-width: unset;
        width: 875px;
        right: -8%;
    }

    @include media(desktop) {
        right: -24px;
    }

    @include media(screen) {
        width: 1060px;
    }

    li {
        width: auto;
        margin: 0 $s-s $s-s 0;

        @include media(tablet) {
            margin: 0 $s-m $s-m 0;
            &:nth-child(1) {order: 1;}
            &:nth-child(2) {order: 2;}
            &:nth-child(3) {order: 5;}
            &:nth-child(4) {order: 6;}
            &:nth-child(5) {order: 3;}
            &:nth-child(6) {order: 4;}
            &:nth-child(7) {order: 7;}
            &:nth-child(8) {order: 8;}
        }

        @include media(screen) {
            margin: 0 24px 16px 0;
        }
    }
}