@import '../../../scss/globals';



.no-webp {
    .about-gallery-1 picture {
        background-image: url("../../../img/about-us/gallery/gallery-1-min.png");
        @include media(tablet) {
            background-image: url("../../../img/about-us/gallery/gallery-1-dk.png");
        }
    }

    .about-gallery-2 picture {
        background-image: url("../../../img/about-us/gallery/gallery-2-min.png");
        @include media(tablet) {
            background-image: url("../../../img/about-us/gallery/gallery-2-dk-min.png");
        }
    }
}

.webp {
    .about-gallery-1 picture {
        background-image: url("../../../img/about-us/gallery/gallery-1.webp");
        @include media(tablet) {
            background-image: url("../../../img/about-us/gallery/gallery-1-dk.webp");
        }
    }

    .about-gallery-2 picture {
        background-image: url("../../../img/about-us/gallery/gallery-2.webp");
        @include media(tablet) {
            background-image: url("../../../img/about-us/gallery/gallery-2-dk.webp");
        }
    }
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 88px;
    background-color: $bg-blue-light;
    position: relative;
    overflow: hidden;
    @include media(screen) {
        padding-top: 140px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 219px;
        height: 192px;
        background-image: url("../../../img/about-us/round-shape-1.svg");
        background-size: cover;
        top: -88px;
        right: -38px;
        @include media(desktop) {
            width: 372px;
            height: 326px;
            top: -185px;
            right: 0;
        }
    }


    &-header.wrapper,
    &-body.wrapper {
        position: relative;
        margin: 0 auto 30px;
        max-width: 730px;
        @include media(smartphone) {
            padding: $s-l $s-xxl;
        }
        @include media(screen) {
            padding: 0;
        }
        @include media(desktop) {
            margin: 0 auto 60px;
        }
        .subtitle {margin-bottom: 56px;}

    }

    &-header.wrapper {
        &:before {
            content: '';
            position: absolute;
            width: 141px;
            height: 111px;
            background-image: url("../../../img/about-us/round-shape-2.svg");
            background-size: cover;
            bottom: -82px;
            left: -60px;
            @include media(screen) {
                width: 281px;
                height: 206px;
                left: -43%;
                bottom: -30%;
                margin-bottom: 130px;
            }
        }
    }
    &-body.wrapper {
        margin-bottom: 0;
        padding: 24px 24px 0 24px;
    }

    .paragraph {
        margin-bottom: 0;
        p {margin-bottom: 32px;}
    }

    &-gallery-1,
    &-gallery-2 {
        position: relative;
        height: 310px;
        width: 100%;
        margin: 0 auto $s-xxl;
        z-index: 3;

        @media (min-width: 375px) {height: 360px;}
        @media (min-width: 450px) {height: 400px;}
        @media (min-width: 500px) {height: 450px;}
        @include media(tablet) {
            height: 480px;
            background-size: contain;
            margin: 80px auto 144px;
            background-position: center;
        }

        @include media(screen) {
            height: 618px;
            background-size: auto;
        }

        picture {
            height: 100%;
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            z-index: 1;
        }
    }

    &-gallery-1 {
        @include media(tablet) {
            &:after {
                content: '';
                height: 340px;
                width: 270px;
                background-color: rgba(0, 219, 245, 0.2);
                position: absolute;
                left: 0;
                top: -80px;
            }

            span {
                height: 319px;
                width: 319px;
                background-image: linear-gradient(4deg, $turquoise 6%, $turquoise-medium 97%);
                transform: skewY(2deg);
                position: absolute;
                bottom: -80px;
                right: 0;
                z-index: 1;
            }
        }

    }

    &-gallery-2 {
        @include media(tablet) { margin: 30px auto 144px; }
        picture {
            position: relative;
        }
    }

}