@import '../../../../scss/globals';

.anchor {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    transition: all .3s;
    position: relative;
    @include media(screen) {font-size: 22px;}

    &.orange {color: $orange-medium;}
    &.green {color: $green-light;}
    &.blue {color: $blue;}

    @include media(tablet) {
        &.orange {
            &:hover {filter: brightness(1.8);}
            &:active, &:focus {filter: brightness(0.9);}
        }
        &.green {
            &:hover {filter: brightness(1.2);}
            &:active, &:focus {filter: brightness(0.7);}
        }
        &.blue:hover {
            &:hover {filter: brightness(1.4);}
            &:active, &:focus {filter: brightness(0.8);}
        }
    }

    [class^=icon-] {
        position: absolute;
        right: -12px;
        top: 7px;
        @include media(screen) {
            height: 20px;
            right: -20px;
            top: 2px;
        }
    }
}