@import '../../../scss/globals';

.no-webp {
    .home-gallery__img {
        &:first-child {
            background-image: url("../../../img/home/gallery-1/gallery-1-min.png");
            @include media(tablet) {background-image: url("../../../img/home/gallery-1/gallery-1-min-dk.png");}
        }

        &:last-child {
            background-image: url("../../../img/home/gallery-2/gallery-2-min.png");
            @include media(tablet) {background-image: url("../../../img/home/gallery-2/gallery-2-min-dk.png");}
        }
    }
}

.webp {
    .home-gallery__img {
        &:first-child {
            background-image: url("../../../img/home/gallery-1/gallery-1-min.png");
            @include media(tablet) {background-image: url("../../../img/home/gallery-1/gallery-1-dk.webp");}
        }

        &:last-child {
            background-image: url("../../../img/home/gallery-2/gallery-2-min.png");
            @include media(tablet) {background-image: url("../../../img/home/gallery-2/gallery-2-dk.webp");}
        }
    }
}


.home {
    overflow: hidden;

    section {
        position: relative;

        &:first-of-type {
            @include bg-gradient(blue-yellow);
            &:before {
                @include media(screen) {
                    content: '';
                    display: block;
                    height: 157px;
                    width: 223px;
                    background-image: url("../../../img/home/shapes/shape-1.png");
                    position: absolute;
                    left: 87px;
                    bottom: 214px;
                }
            }


            .wrapper {
                padding: 20% $s-l;
                @include media(tablet) {
                    display: flex;
                    justify-content: space-between;
                }

                @include media(desktop) {padding: 100px;}
                @include media(screen) {padding: 247px 57px 411px;}
                @include media(screen-wide) {padding: 247px 0 411px 100px;}

                .home-picture {
                    @include media(tablet){width: 40%;}
                    @include media(screen) {
                        width: 600px;
                        height: 655px;
                    }
                }

            }
        }


        &:nth-child(4) {
            position: relative;
            @include bg-gradient(light-yellow);
            .wrapper {
                padding: $s-xxl $s-l 0;
                @include media(tablet) {
                    position: relative;
                    padding: $s-xxl $s-l 130px;
                }
                @include media(screen) { padding: 210px $s-l 130px;}
            }

            .home-picture {
                margin-top: 88px;
                width: 132%;
                transform: translateX(-12%);
                @include media(smartphone) {width: 465px;}
                @media (min-width: 440px) {transform: translateX(-10%);}
                @media (min-width: 460px) {transform: translateX(-5%);}
                @media (min-width: 500px) {transform: translateX(0);}
                @include media(tablet) {
                    position: absolute;
                    left: 0;
                    top: 125px;
                    width: 40%;
                    max-width: 350px;
                    margin-top: 0;
                }

                @media (min-width: 940px) {left: 5%;}
                @include media(screen) {
                    max-width: unset;
                    width: 683px;
                    height: 683px;
                    left: -12%;
                }

                @include media(screen-wide) {
                    left: -4%;
                    top: 235px;
                }
            }
        }


        &:nth-child(5) {
            background-color: $bg-yellow;
            padding: 64px $s-l 46px;
        }

        &:nth-child(8) {
            background-color: $bg-blue-light;
            @include media(screen) {padding: 96px 0 133px;}
        }

        .content.wrapper {
            padding: 64px $s-l $s-xxl;
            @include media(smartphone) {
                padding: 64px 72px $s-xxl;
            }
        }

    }

    &-picture {
        margin: auto;
        max-width: 465px;
        @include media(desktop) {
            margin: 0;
            max-width: 600px;
        }

        img {width: 100%;}
    }

    &-gallery {
        position: relative;
        overflow: hidden;
        height: 419px;
        background-color: $bg-yellow;
        background-image: url("../../../img/home/shapes/bg-gallery.svg");
        background-repeat: no-repeat;
        background-position: -5% center;
        background-size: auto;
        @include media(smartphone) {
            background-position: -80% center;
        }
        @media (min-width: 460px) {
            background-position: center center;
        }
        @include media(tablet) {
            height: 820px;
            background-position: 65% 50%;
            background-image: url("../../../img/home/shapes/bg-gallery-dk.svg");
        }

        &:after {
            content: '';
            display: block;
            width: 94px;
            height: 94px;
            position: absolute;
            left: 20%;
            top: 36%;
            background-image: url("../../../img/home/shapes/grey-dots.png");
            mix-blend-mode: overlay;
            background-size: cover;
            z-index: 1;
            @include media(smartphone) {
                left: 30%;
            }
            @include media(tablet) {
                left: 40%;
                width: 191px;
                height: 191px;
            }
        }

        .wrapper {
            display: flex;
            height: 100%;
            justify-content: space-between;
            padding: $s-xxl 0 64px;
            @include media(smartphone) {
                justify-content: center;
                padding: $s-xxl 0 96px;
            }
        }

        &__img {
            z-index: 2;

            &:first-child,
            &:last-child {
                top: 25%;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:first-child {
                height: 190px;
                width: 180px;
                left: 0;
                margin-top: 26px;
                @include media(tablet) {
                    margin-top: 75px;
                    height: 387px;
                    width: 527px;
                }
            }

            &:last-child {
                height: 232px;
                width: 202px;
                margin: 60px 0 0 56px;
                right: 0;
                @include media(smartphone) {
                    margin: 60px 0 0 12px;
                    height: 232px;
                    width: 222px;
                }
                @include media(tablet) {
                    margin: 150px 0 0 56px;
                    height: 473px;
                    width: 642px;
                }
            }
        }

    }
}