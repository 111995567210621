// Breakpoints variables
$breakpoints: (
        "mobile":       $mobile,
        "smartphone":   $smartphone,
        "tablet":       $tablet,
        "desktop":      $desktop,
        "screen":       $screen,
        "screen-wide":  $screen-wide
);

//@include media(tablet){}
@mixin media($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}