@import '../../../scss/globals';

.lang-selector {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;


    &__item {
        cursor: pointer;
        opacity: 0.5;
        &:first-child {
            padding-right: $s-ss;
            position: relative;
            &:after {
                content: '';
                height: 23px;
                width: 1px;
                background-color: rgba($text, .15);
                position: absolute;
                top: -4px;
                right: 0;
                @include media(desktop) {top: 1px;}
            }
        }

        &:last-child {
            padding-left: $s-ss;
        }

        &.selected {
            opacity: unset;
        }
    }


    img:first-child {
        border-right: solid 1px rgba($text, .1);
    }

}