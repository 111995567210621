@mixin bg-image( $color, $url, $repeat: no-repeat, $position: center center, $size: cover ) {
    background-color: $color;
    background-image: url($url);
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
}

@mixin box-shadow($type) {
    @if $type == light {
        box-shadow: 0 2px 4px 0 rgba(10, 20, 74, 0.2);
    }

    @if $type == medium {
        box-shadow: 0 8px 20px 0 rgba(10, 20, 74, 0.2);
    }

    @if $type == blue {
        box-shadow: 0 10px 20px 0 rgba(0, 123, 255, 0.15);
    }

    @if $type == blue-medium {
        box-shadow: 0 10px 20px 0 rgba(0, 123, 255, 0.25);
    }
    @if $type == blue-hover {
        box-shadow: 0 20px 40px 0 rgba(0, 123, 255, 0.25);
    }
}


@mixin dot($type: normal) {

    @if $type == normal {
        height: 14px;
        width: 14px;
        @include media(screen) {
            height: 21px;
            width: 21px;
        }
    }

    @if $type == small {
        height: 11px;
        width: 11px;
        @include media(screen) {
            height: 18px;
            width: 18px;
        }
    }

    display: inline-block;
    border-radius: 50%;
    background-color: $white;
    border: 3px solid $text;
    box-shadow:
            0 0 0 2px $white,
            0 4px 12px 0 rgba(10, 20, 74, 0.3)
    ;

    @include media(screen) {
        border: 6px solid $text;
        box-shadow:
                0 0 0 4px $white,
                0 4px 12px 0 rgba(10, 20, 74, 0.3)
        ;
    }
}