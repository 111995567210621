@import '../../../../scss/globals';

.footer {
    font-family: $f-title;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px $s-l 297px;
    background-color: $gray;
    background-image: url("../../../../img/footer/bg-footer.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    @include media(smartphone) {
        padding: 100px 70px 297px;
    }

    @include media(tablet) {
        background-image: url("../../../../img/footer/bg-footer-dk.png");
    }

    @include media(desktop) {
        padding: 152px 112px 400px;
    }

    .footer-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;        
        gap: 30px;
        padding: 30px 0 0;
        @include media(screen) {
            justify-content: flex-start;
            max-width: 1260px;
        }
    }

    .baby-friendly {               
        img { object-fit: contain; }
        @include media(screen) {
            display: flex;
            margin: 0;
        }
    }

    .GPTW {
        img { min-width: 50px; }
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media(screen) {
            width: 100%;
            max-width: 1260px;
            justify-content: space-between;
            padding-top: $s-m;
            flex-direction: row;
            text-align: left;
        }

        .linkedin {
            display: flex;
            align-items: center;
            margin: 15px auto $s-l;
            @include media(screen) {
                margin: 0 0 0 $s-xxl;
                order: 3;

            }

            img {
                @include media(screen-wide) {
                    height: 32px;
                }
            }

            span {
                font-family: $f-open-sans;
                font-size: 11px;
                font-weight: 600;
                color: rgba($text, 0.5);
                margin-right: $s-s;
                @include media(screen-wide) {
                    font-size: 16px;
                }
            }
        }

        .legal {
            padding-top: $s-l;
            border-top: 1px solid rgba($text, .1);
            @include media(screen) {
                display: flex;
                border-top: none;
                padding-top: 0;
                order: 2;
            }

            p {
                font-size: 12px;
                font-weight: 300;
                margin-bottom: $s-m;
                color: rgba($text, .8);
                @include media(screen) {
                    margin: 0 $s-xxl 0 0;
                }
                @include media(screen-wide) {
                    font-size: 18px;
                }
            }
            &-links {
                a {
                    font-size: 15px;
                    line-height: 1.8;
                    @include media(screen) {
                        line-height: 1.2;
                    }
                }
                span { margin: 0 8px; }
                @include media(screen) {
                    font-size: 18px;
                }
            }
        }

    }

    .lang-selector {
        margin-top: $s-s;
        @include media(desktop) {display: none;}
    }
}