@import '../../../../../../scss/globals';

.navigation-tabs {
    padding: $s-l 0 $s-l 0;
    @include media(tablet) {padding: $s-ll 0 56px 0;}
    @include media(screen) {padding: 0;}

    ul {
        font-size: 23px;
        display: flex;
        flex-direction: column;
        margin-top: $s-m;

        @include media(screen) {
            font-family: $f-text;
            font-size: 18px;
            flex-direction: row;
            margin-top: 0;
        }

        li {
            position: relative;
            border-top: 1px solid rgba($text, .15);
            margin-left: 37px;
            @include media(screen) {
                border-top: none;
                &:first-child {display: none;}
            }
            @include media(tablet) { margin-left: 23px; }
            &:not(:nth-child(2)) {
                @include media(screen) {margin-left: 20px;}
                @include media(screen-wide) {margin-left: 32px;}
            }

            &:last-child {
                border-bottom: 1px solid rgba($text, .15);
                @include media(screen) {
                    margin-left: auto;
                    border-bottom: none;
                }

                a {
                    transition: all .3s;
                    @include media(screen) {
                        color: $white;
                        border-radius: 4px;
                        padding: $s-ss $s-xl;
                        letter-spacing: -0.32px;
                        @include box-shadow(light);
                        @include bg-gradient(orange);
                        &:hover {
                            @include box-shadow(medium);
                        }
                        &:active,
                        &:focus {
                            background-image: none;
                            background-color: $orange-medium;
                        }
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                padding: $s-ss 0 $s-ss 0;
                letter-spacing: -0.4px;
                opacity: 0.5;
                @include media(tablet) {
                    &:hover {
                        opacity: 1;
                        font-weight: 500;
                        &:before {
                            content: '';
                            width: 372px;
                            height: 54px;
                            background-color: rgba($bg-blue, .3);
                            position: absolute;
                            left: -42px;
                        }
                    }
                }

                @include media(screen){
                    opacity: unset;
                    padding: $s-m 0 $s-ss;
                    &:hover {
                        &:before {content: none;}
                    }
                }

                &.active {
                    opacity: 1;
                    font-weight: 500;
                    span {
                        border-left: 5px solid #007bff;
                        height: 55px;
                        position: absolute;
                        left: -37px;
                        @include media(tablet) { left: -42px; }
                        @include media(screen) {
                            border: none;
                        }
                    }
                    @include media(tablet) {
                        &:before {
                            content: '';
                            width: 372px;
                            height: 54px;
                            position: absolute;
                            left: -42px;
                        }
                    }
                    @include media(screen) {
                        font-weight: 600;
                        &:before {content: none;}
                    }
                }

                div {
                    width: 24px;
                    margin-right: $s-m;
                    position: relative;
                    @include media(screen) {display: none;}

                    p {padding-top: 2px;}
                }

                p {
                    position: relative;
                    align-self: baseline;
                }
            }
        }
    }

}