@import '../../../../scss/globals';

.perks {
    position: relative;
    background-color: $bg-yellow;
    padding: 64px $s-m;
    z-index: 1;
    @include media(smartphone) {
        padding: 64px $s-xl;
    }
    @include media(tablet) {
        padding: 74px 95px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 930px;
        margin: 0 auto;
    }

    &:before {
        content: '';
        background-image: linear-gradient(4deg, $turquoise 6%, $turquoise-medium 97%);
        transform: skewY(-2deg);
        width: 100%;
        height: 100%;
        position: absolute;
        right:  0;
        top: 0;
        @include media(tablet) {
            transform: skewY(-1deg);
            background-image: linear-gradient(13deg, $turquoise 22%, $turquoise-medium 83%);
        }
    }

    li {
        width: 46%;
        text-align: center;
        z-index: 1;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-bottom: 36px;
        }

        @include media(tablet) {
            width: 26%;
            &:nth-child(1) {order: 1;}
            &:nth-child(3) {order: 2;}
            &:nth-child(5) {order: 3;}
            &:nth-child(2) {
                order: 4;
                margin-bottom: 0;
            }
            &:nth-child(4) {
                order: 5;
                margin-bottom: 0;
            }
            &:nth-child(6) {order: 6;}
        }

        img {
            margin-bottom: 0;
            @include media(tablet) {
                margin-bottom: $s-xs;
                height: 48px;
            }
        }

        .title {
            font-family: $f-text;
            font-size: 15px;
            line-height: 1.3;
            font-weight: 700;
            margin-bottom: $s-xs !important;
            @include media(screen) {
               font-size: 22px;
            }
        }

        .info {
            font-size: 14px;
            opacity: 0.7;
            @include media(screen) {
                font-size: 20px;
            }
        }
    }
}