@import '../../../../scss/globals';

.home-section {
    max-width: 550px;
    margin: 0 auto;
    @include media(smartphone) {padding: 0 46px;}
    @include media(tablet) {width: 60%;}

    &--about-us {
        margin-bottom: 62px;

        @include media(desktop) {
            padding: 0;
            margin-right: 78px;
            max-width: 540px;
        }

        @include media(screen) {padding: 117px 0 0;}
        @include media(screen-wide) {width: calc(100% - 600px);}
    }

    &--our-minds {
        position: relative;
        padding: 0 36px 324px;
        @include media(tablet) {
            margin: 0 0 0 auto;
            padding: 64px $s-xxl 220px;
        }

        @include media(desktop) {margin: 0 7% 0 auto;}
        @media (min-width: 1100px) {margin: 0 3% 0 auto;}

        @include media(screen) {
            padding: 64px 0 400px;
            &:before {
                content: '';
                background-image: url("../../../../img/blocks/our-minds/round-shape.svg");
                width: 168px;
                height: 94px;
                position: absolute;
                right: -140px;
                bottom: 38%;
            }
        }

        @media (min-width: 1300px) {margin: 0 10% 0 auto;}
        @include media(screen-wide){
            margin: 0 4% 0 auto;
            padding: 127px 0 400px;
            width: calc(100% - 700px);
        }
        .paragraph {
            margin-bottom: 0;
        }
    }

    &--life-at-tmh {
        padding: $s-l;
        @include media(smartphone) {padding: 0 $s-xxl;}
        @include media(desktop) {max-width: 730px;}
        @include media(screen) {
            padding: 0 0 $s-xxl;
        }
    }

    &--careers {
        padding: $s-l;
        @include media(smartphone) {padding: 70px 60px 46px;}
        @include media(screen) {
            padding: 0;
            max-width: 730px;
        }
    }

    .section-icon {
        margin: 0 0 $s-s -18px;
        @include media(tablet) {
            height: 64px;
            margin: 0 0 $s-s -28px;
        }
    }

    .cta {margin-right: $s-s;}
}