@import '../../../../scss/globals';


.no-webp {
    .headquarter {

        &--main-office picture {
            background-image: url("../../../../img/contact/gallery/sntcugat-offices-min.jpeg");
        }

        &--second-office picture {
            background-image: url("../../../../img/contact/gallery/bcn-offices-min.jpg");
        }

    }
}

.webp {
    .headquarter {

        &--main-office picture {
            background-image: url("../../../../img/contact/gallery/sntcugat-offices-min.webp");
        }

        &--second-office picture {
            background-image: url("../../../../img/contact/gallery/bcn-offices.webp");
        }
    }
}



.headquarter {
    position: relative;
    max-width: 430px;
    margin: 0 auto 88px;
    z-index: 5;
    @include media(desktop) {
        max-width: 540px;
        margin: 0 0 88px;
        width: 49%;
    }

    &-badge {
        content: '';
        height: 32px;
        width: 366px;
        background-image: linear-gradient(87deg, $turquoise-medium, $turquoise 100%);
        display: block;
        position: absolute;
        bottom: -15px;
        right: 0;
        z-index: 1;
        @include media(desktop) {
            height: 62px;
            width: 512px;
            right: -20px;
            bottom: -20px;
        }
    }

    &-title {
        margin-left: $s-ss;
        position: relative;
        transform: skewY(-1deg);
        background-color: $text;
        height: 47px;
        width: 284px;
        z-index: 1;
        @include media(smartphone) {
            margin-left: $s-l;
            width: 320px;
        }

        &:after {
            content: '';
            background-image: url("../../../../img/icons/icon-location.svg");
            height: 20px;
            width: 16px;
            display: block;
            position: absolute;
            left: 24px;
            top: 13px;
            filter: opacity(.7);
        }

        p {
            font-family: $f-title;
            color: $white;
            font-weight: 500;
            position: absolute;
            top: 15px;
            left: 48px;
            z-index: 2;
            transform: skewY(1deg);
            @include media(screen) {
                font-size: 22px;
                top: 12px;
            }
        }

        img {
            position: absolute;
            top: 15px;
            left: 26px;
            z-index: 2;
        }
    }

    picture {
        margin-top: -2px;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 250px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include media(tablet) {
            height: 350px;
        }
    }

    &-map {
        width: 100%;
        height: 179px;

    }

    &-label {
        font-size: 16px;
        padding: $s-xl 48px;
        background-color: $white;
        transform: skewY(-1deg);
        margin: -5px 0 0 auto;
        max-width: 390px;
        position: relative;
        z-index: 3;
        @include box-shadow(blue);
        @include media(smartphone) {
            font-size: 16px;
            padding: $s-xl $s-xxl;
        }
        @include media(desktop) {
            font-size: 20px;
            line-height: 32px;
            margin: -5px 0 0;
            height: 195px;
            width: 100%;
            max-width: unset;
            padding: $s-xxl 35px;
            transform: skewY(1deg);
        }

        * {
            transform: skewY(1deg);
            width: 95%;
            @include media(desktop) { transform: skewY(-1deg); }
        }
    }

    &--main-office {

        &:before {
            content: '';
            width: 163px;
            height: 178px;
            display: block;
            position: absolute;
            right: 10%;
            top: 0;
            mix-blend-mode: overlay;
            background-image: url("../../../../img/contact/square-shape-1.svg");
            @include media(smartphone) { right: 22px; }
            @include media(desktop) {
                top: -13px;
                right: 132px;
            }
        }

        .headquarter-label {
            @include media(desktop) {
                transform: skewY(-1deg);
                * {transform: skewY(1deg);}
            }
        }

        span {
            @include media(desktop) {
                right: unset;
                left: -20px;
            }
        }

    }

    &--second-office {

        &:before {
            content: '';
            width: 164px;
            height: 219px;
            display: block;
            position: absolute;
            right: 10%;
            top: -54px;
            background-image: url("../../../../img/contact/round-shape-2.svg");
            background-size: cover;
            @include media(smartphone) {right: 22px;}
            @include media(tablet) {
                height: 111px;
                width: 83px;
                right: 312px;
                top: -15px;
                transform: scaleX(-1);
            }
        }

        .headquarter-title {
            @include media(tablet) {
                margin: 0 $s-l 0 auto;
            }
        }
    }
}
