@mixin bg-gradient($type) {

    @if $type == gray {
        background-image: linear-gradient(to bottom, #f4f5f7 50%, #e6e7ec 94%);
    }

    @if $type == white-blue {
        background-image: linear-gradient(to bottom, #ffffff 38%, #e6f1fc);
    }

    @if $type == blue-yellow {
        background-image: linear-gradient(to bottom, #e6f1fc 20%, #fff4e9 73%);
    }

    @if $type == light-blue {
        background-image: linear-gradient(to top, #d9edfe 100%, #e6f1fc 25%);
    }

    @if $type == light-yellow {
        background-image: linear-gradient(to bottom, #f9fcf4 37%, #f7faf4 100%);
    }

    @if $type == orange {
        background-image: linear-gradient(287deg, #ff9769, $orange-medium);
    }

    @if $type == electric-blue {
        background-image: linear-gradient(to bottom, #007bff 47%, #0055ff);
    }

    @if $type == dark-blue {
        background-image: linear-gradient(to top, #092064 100%, #0a144a 25%);
    }

    @if $type == dark-blue-stats {
        background-image: linear-gradient(to top, #092064 30%, #0a144a 70%)
    }
}
