@import '../../../../scss/globals';


.timeline {
    padding: 0 $s-l 0 42px;
    position: relative;
    max-width: 735px;
    @include media(tablet) {
        padding: 0 $s-l 0 65px;
    }


    &:before {
        @include media(desktop) {
            content: '';
            display: block;
            width: 170px;
            height: 136px;
            background-image: url("../../../../img/about-us/round-shape-3.svg");
            background-size: cover;
            position: absolute;
            top: 40%;
            right: -13%;
        }
    }

    &-countries {
        font-size: 14px;
        padding: 0 0 90px 18px;
        @include media(smartphone) {
            font-size: 16px;
            padding: 0 0 90px $s-m;
        }

        @include media(screen) {
            font-size: 24px;
        }

        &__header {
            padding-left: $s-l;
            position: relative;
            margin-bottom: 57px;
            @include media(smartphone) {
                padding-left: 38px;
            }

            &:before {
                content: '';
                display: block;
                height: 220px;
                width: 3px;
                position: absolute;
                left: 4px;
                top: 0;
                background-image: linear-gradient($blue-navy 50%, $blue 100%);
                @include media(screen) {
                    top: 16px;
                    width: 4.5px;
                }
            }


            p:first-child {
                font-size: 16px;
                font-family: $f-title;
                font-weight: 600;
                margin-bottom: $s-s;
                @include media(smartphone) {
                    font-size: 18px;
                }

                @include media(screen) {
                    font-size: 28px;
                }

                &:before {
                    content: '';
                    @include dot();
                    position: absolute;
                    left: -2px;
                    @include media(screen) {
                        left: -5px;
                    }
                }
            }
        }


        &__ending {
            padding-left: 76px;
            position: relative;
            @include media(screen) {
                padding-left: 140px;
            }

            &:before {
                content: '';
                position: absolute;
                left: 59px;
                top: 4px;
                @include dot(small);
                @include media(screen) {
                    left: 110px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: -82px;
                left: 4px;
                background-image: url("../../../../img/about-us/green-final-arrow.svg");
                width: 48px;
                height: 99px;
                z-index: 1;
                @include media(screen) {
                    background-image: url("../../../../img/about-us/green-final-arrow-dk.svg");
                    width: 93px;
                    height: 144px;
                    top: -115px;
                }
            }

            p:first-child {
                font-family: $f-title;
                font-weight: 600;
                margin-bottom: $s-xs;
                @include media(screen) {
                    font-size: 28px;
                }
                a {
                    margin-left: $s-s;
                    @include media(tablet) {
                        &:hover {text-decoration: underline;}
                    }
                }
            }

            p:last-child {
                position: relative;
                color: rgba($text, .8);
                &:after {
                    content: '';
                    width: 50px;
                    height: 3px;
                    background-color: $turquoise-light;
                    border-radius: 10px;
                    position: absolute;
                    bottom: -1px;
                    left: 10px;
                    transform: skewY(-2deg);
                    @include media(screen) {
                        width: 82px;
                        left: 19px;
                    }
                }
            }
        }
    }

}