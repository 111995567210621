@import '../../../../../../scss/globals';

.navigation-footer {
    padding: $s-xl $s-l;
    margin-top: 70px;
    @include media(tablet) {display: none;}

    &__title {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__contact {
        font-family: $f-text;
        margin: $s-m 0 $s-l 0;
        display: flex;
        align-items: center;
        p {font-weight: 600;}

        .email {
            margin-left: $s-m;

            div {
                display: flex;
                align-items: center;
                margin-top: $s-xs;

                img {
                    margin-right: $s-xs;
                    width: 16px;
                    height: 12px;
                }

                a {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

        }
    }

    &__copyright,
    &__contact p {
        font-size: 12px;
        opacity: 0.5;
    }

    &__copyright {
        font-family: $f-text;
        margin-top: $s-l;
    }
}