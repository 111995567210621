@import '../../../scss/globals';





.contact {
    position: relative;
    padding: 88px 0 0;
    background-color: $bg-blue-light;
    overflow: hidden;
    @include media(tablet) {
        padding: 140px 0 0;
    }

    &:before {
        content: '';
        width: 206px;
        height: 221px;
        position: absolute;
        top: -112px;
        right: -50px;
        background-image: url("../../../img/contact/round-shape-1.svg");
        background-size: contain;
        background-repeat: no-repeat;
        @include media(tablet) {
            width: 364px;
            height: 391px;
            top: -185px;
            right: 113px;
        }
    }

    &-header,
    &-body {
        max-width: 730px;
        margin: 0 auto;
        @include media(smartphone) {padding: 0 $s-xxl;}
    }

    &-header {
        padding: 0 $s-xl;
        @include media(tablet) {
            padding: 0;
            p:last-child {margin-bottom: 110px;}
        }
    }


    &-gallery {
        position: relative;
        max-width: 1110px;
        margin: 0 auto 76px;
        @include media(desktop) {
            display: flex;
            justify-content: space-between;
            margin: 0 auto 96px;
        }
        @include media(screen) {
            &:before {
                content: '';
                background-image: url("../../../img/contact/round-shape-4.svg");
                width: 221px;
                height: 206px;
                position: absolute;
                left: -25%;
                bottom: -10%;
            }
        }
    }

    &-body {
        font-size: 18px;
        line-height: 24px;
        margin: 0 auto $s-xxl;
        @include media(tablet) {padding: 0;}
        @include media(screen) {
            font-size: 28px;
            line-height: 38px;
            margin: 0 auto 96px;
        }
    }

    &-recruiters {
        position: relative;
        padding: 0 $s-xl 74px;
        @include media(screen-wide) {
            padding: 0 0 112px;
            &:before {
                content: '';
                background-image: url("../../../img/contact/round-shape-3.svg");
                width: 1135px;
                height: 1176px;
                position: absolute;
                bottom: -127%;
                right: -37%;
            }
        }

        &__header {
            max-width: 730px;
            margin: 0 auto 74px;
            padding: 0 $s-m;
            text-align: center;
            @include media(smartphone) {padding: 0 $s-xxl;}
            @include media(tablet) {
                padding: 0;
                margin: 0 auto 96px;
            }
            .title h3, .subtitle {
                text-align: left;
                @include media(tablet) { text-align: center; }
            }
            .subtitle {
                @include media(screen) {
                    font-size: 24px;
                }
            }
        }

        &__wrapper {
            max-width: 920px;
            margin: 0 auto;
            @include media(desktop) {
                display: flex;
                justify-content: space-between;
            }
        }

        &-sub-title {
            font-size: 21px;
            margin-bottom: 74px;
            @include media(screen) {
                font-size: 28px;
                line-height: 32px;
                margin: 0 auto 96px;
            }
        }
    }
}