@import '../../../../../scss/globals';


.department-card {
    font-family: $f-title;
    background-color: $white;
    border-radius: 10px;
    padding: $s-xl 40px;
    width: 100%;
    height: 100%;
    @include box-shadow(blue);
    margin-bottom: $s-m;
    position: relative;
    z-index: 3;
    transition: box-shadow .3s;
    @include media(tablet) {
        &:hover {@include box-shadow(blue-hover);}
        &:active, &:focus {
            @include box-shadow(blue);
            background-color: $neutral-0;
        }
    }
    @include media(desktop) {
        margin-bottom: 0;
        padding: $s-xl 24px $s-xl 40px;
    }

    &:before {
        content: '';
        width: 10px;
        height: 100%;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
    }

    &.orange {
        &:before {background-image: linear-gradient(to bottom, #ff8b00, #ef6a02);}
    }

    &.red {
        &:before {background-image: linear-gradient(to top, #fd7184, #f73c5b);}
    }

    &.green {
        &:before {background-image: linear-gradient(to bottom, #00ba81, #67e8b5);}
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: $s-s !important;
        @include media(screen) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        color: $text;
        opacity: .7;
        margin-bottom: $s-m;
        line-height: 1.4;
        @include media(desktop) {
            margin-bottom: 28px;
        }
    }

    &-location {
        display: flex;
        align-items: flex-end;
        @include media(desktop) {
            position: absolute;
            bottom: 30px;
            left: 40px;
            width: 87%;
        }

        &-icon {
            margin-right: $s-s;
            filter: brightness(0.8);
        }
    }


    span {
        font-weight: 600;
        color: rgba($text, .5);
    }

    &-arrow-icon {
        position: absolute;
        right: 35px;
        bottom: 35px;
        @include media(desktop) {
            right: 30px;
            bottom: 3px;
        }
    }
}