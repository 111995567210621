@import '../../../../scss/globals';

.stats {
    position: relative;
    background-size: auto;
    color: $white;
    overflow: hidden;
    width: 100%;
    @include bg-gradient(dark-blue-stats);

    &:before,
    &:after {
        content: '';
        position: absolute;
    }

    &:before {
        width: 312px;
        height: 220px;
        background-image: url("../../../../img/blocks/stats/bg-left-shape.svg");
        bottom: 0;
        left: 0;
    }

    &:after {
        width: 261px;
        height: 175px;
        background-image: url("../../../../img/blocks/stats/bg-right-shape.svg");
        top: 0;
        right: 0;
    }

    h4 {
        color: $white;
        @include media(tablet) {line-height: 64px;}
    }

    &-content.wrapper {
        padding: 128px 53px 184px $s-l;
        z-index: 2;
        position: relative;
        @include media(tablet) {
            padding: 96px $s-l 156px;
        }
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 30%;
            height: 372px;
            width: 352px;
            z-index: 1;
            background-image: url("../../../../img/blocks/stats/bg-globe-mb.svg");
            @include media(tablet) {
                top: 350px;
                right: -12%;
                height: 1376px;
                width: 1271px;
                background-image: url("../../../../img/blocks/stats/bg-globe-dk.svg");
            }
        }
    }

    &-heading,
    &-ending {
        z-index: 2;
        position: relative;
        @include media(smartphone) {
            padding: 0 $s-xxl;
        }
        @include media(screen) {
            width: 700px;
            margin: 0 auto;
            padding: 0;
        }
    }

    &-heading {
        padding: 0 0 0 53px;
        * {
            z-index: 2;
            position: relative;
        }
        @include media(tablet) {
            &:before {
                content: '';
                background-image: url("../../../../img/blocks/stats/bg-heading-shape.svg");
                position: absolute;
                top: 17px;
                left: -85px;
                width: 149px;
                height: 74px;
                z-index: 1;
            }
        }
    }

    &-ending {
        font-family: $f-title;
        font-size: 24px;
        font-weight: 500;
        padding: 0 53px;
        @include media(screen) {
            font-size: 34px;
        }
    }

    &-grid {
        display: flex;
        flex-direction: column;
        margin: 146px 0 60px;
        position: relative;
        z-index: 2;
        @include media(desktop) {
            max-width: 1000px;
            margin: 268px auto 187px;
        }

        .flex {
            display: flex;
        }

        &__item {

            &.members,
            &.slack {
                .num {margin-left: $s-s;}
            }

            &.slack { text-align: right;}

            &.nationalities, &.slack {
                align-self: flex-end;
            }

            .intro {
                font-size: 14px;
                font-style: italic;
                color: $white;
                font-weight: 300;
                @include media(tablet) {
                    font-size: 24px;
                }
            }

            .num {
                font-size: 56px;
                font-weight: 600;
                @include media(tablet) {
                    font-size: 88px;
                }
            }

            p {
                font-weight: 500;
                @include media(tablet) {
                    font-size: 28px;
                }
            }

            &.glassdoor {
                top: -25px;
                position: relative;
                @include media(desktop) {
                    position: inherit;
                }
                img {
                    height: 51px;
                    @include media(tablet) {
                        height: 98px;
                    }
                }
            }

            &.nationalities {
                color: $yellow-light;
                margin-top: 14px;
                text-align: right;
                top: -40px;
                position: relative;
                @include media(tablet) {text-align: left;}
                @include media(desktop) {
                    position: inherit;
                }
                img {
                    @include media(tablet) {
                        height: 65px;
                    }
                }
            }

            &.members {
                color: $turquoise;
                margin: 0 0 57px 40px;
                top: -55px;
                position: relative;
                @include media(tablet) {
                    padding-left: 15%;
                }
                @include media(desktop) {
                    position: inherit;
                }
                img {
                    @include media(tablet) {
                        height: 50px;
                    }
                }
                .intro {
                    text-align: right;
                    font-size: 26px;
                    line-height: 18px;
                    @include media(tablet) {
                        font-size: 42px;
                        line-height: 26px;
                    }
                }
            }

            &.slack {
                color: $orange-medium;
                top: -55px;
                position: relative;
                @include media(tablet) {
                    padding-right: 15%;
                }
                @include media(desktop) {
                    position: inherit;
                }
                img {
                    @include media(tablet) {
                        height: 60px;
                    }
                }
            }


        }

    }


}