@import '../../../../scss/globals';

.no-webp {
    .hero {
        @include bg-gradient(white-blue);

        .wrapper {
            background-image: url("../../../../img/home/hero/hero-mb-min.png");
            @include media(tablet) {
                background-image: url("../../../../img/home/hero/hero-dk-min.png");
            }
        }
    }
}

.webp {

    .hero {
        @include bg-gradient(white-blue);

        .wrapper {
            background-image: url("../../../../img/home/hero/hero-mb.webp");
            @include media(tablet) {
                background-image: url("../../../../img/home/hero/hero-dk.webp");
            }
        }
    }
}


.hero {

    .wrapper {
        color: $white;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 760px;
        padding: 115px $s-m 0;

        @include media(smartphone) {
            padding: 115px 46px 0;
        }

        @media (min-width: 480px) {
            height: 785px;
        }

        @media (min-width: 580px) {
            height: 945px;
        }

        @media (min-width: 680px) {
            height: 1110px;
        }

        @include media(tablet) {
            padding: 165px $s-xl 0;
        }
    }

    &-text {
        max-width: 400px;
        margin: 0 auto;
        @include media(tablet) {
            max-width: 810px;
        }

        &:before {
            content: '';
            width: 60px;
            height: 60px;
            background-image: url("../../../../img/home/hero/grey-dots.png");
            background-size: cover;
            position: absolute;
            top: 58px;
            left: 50%;
            transform: translateX(-50%);
            mix-blend-mode: overlay;
            @include media(tablet) {
                top: 106px;
            }

        }

        h1 {
            font-family: $f-title;
            color: $white;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: $s-m;
            @include media(tablet){
                font-size: 64px;
                line-height: 72px;
            }
        }

        h2 {
            font-family: $f-text;
            font-size: 18px;
            font-weight: 500;
            color: rgba($white, .8);
            line-height: 24px;
            letter-spacing: -0.1px;
            margin-bottom: $s-l !important;
            @include media(tablet){
                font-size: 24px;
                line-height: 38px;
            }
        }
    }

}