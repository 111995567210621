@import '../../../scss/globals';


.legal-page {
    position: relative;
    padding: 108px $s-l 182px;
    @include bg-gradient(gray);
    @include media(smartphone) {padding: 88px $s-xxl 182px;}
    @include media(desktop) {padding: 166px $s-l 286px;}

    &:before,
    &:after {
        content: '';
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &:before {
        width: 143px;
        height: 180px;
        background-image: url("../../../img/legal/round-shape-1.svg");
        left: 0;
        top: -120px;
        @include media(desktop) {
            height: 448px;
            width: 354px;
            top: -260px;
        }
    }

    &:after {
        width: 79px;
        height: 47px;
        background-image: url("../../../img/legal/round-shape-2.svg");
        right: 48px;
        top: 48px;
        @include media(desktop) {
            width: 204px;
            height: 121px;
            top: 66px;
            right: 10%;
        }
    }

    &-wrapper {
        max-width: 730px;
        margin: 0 auto;
        &:after {
            content: '';
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../../../img/legal/round-shape-3.svg");
            right: 0;
            bottom: 0;
            height: 145px;
            width: 278px;
            @include media(desktop) {
                background-image: url("../../../img/legal/round-shape-3.png");
                width: 542px;
                height: 293px;
            }
        }
    }

    h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: $s-xl;
        @include media(desktop) {font-size: 48px;}
    }

    h2 {
        position: relative;
        font-size: 22px;
        line-height: 1.2;
        @include media(desktop) {
            font-size: 26px;
            &:before {
                content: '';
                position: absolute;
                background-size: cover;
                background-repeat: no-repeat;
                width: 253px;
                height: 120px;
                background-image: url("../../../img/legal/square-shape-1.svg");
                left: -100px;
                top: -20px;
            }
        }
    }

    h3 {font-style: italic;}

    h3, h4, h5 {
        font-size: 18px;
        line-height: 1.1;
        @include media(desktop) {
            font-size: 20px;
        }
    }

    h2, h3, h4, h5 {
        font-family: $f-title;
        margin: $s-xl 0 $s-l;
        @include media(desktop) {
            margin: 112px 0 $s-l;
        }
    }

    h5 {padding-left: $s-xxl;}

    p, ul li {
        margin: 0 0 $s-xl;
        line-height: 1.5;
        color: rgba($text, .7);
        @include media(desktop) {
            margin: 0 0 $s-xxl;
        }
    }

    ul {
        padding-left: $s-m;
        list-style: disc;
    }

    ol {
        list-style: none;
        color: rgba($text, .7);
        li {margin: 0 0 $s-ss;}
    }
}