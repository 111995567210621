@import '../../../../../scss/globals';

.sitemap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(screen) {
        width: 100%;
        max-width: 1260px;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: $s-xl;
        border-bottom: 2px solid rgba($text, .1);
    }

    img.logo {
        margin-bottom: $s-xl;
        @include media(screen) {margin-bottom: 0;}
        @include media(screen-wide) {height: 48px;}
    }

    li {
        margin-bottom: $s-m;
        line-height: 1.3;
        @include media(screen) {margin-bottom: 0;}
        @include media(screen-wide) {padding-top: 12px;}
        &:last-child {
            @include media(screen) {
                display: none;
            }
        }

        a {
            font-size: 18px;
            text-transform: uppercase;
            @include media(tablet) {
                &:hover {text-decoration: underline;}
            }

        }
    }
}