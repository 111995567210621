@import '../../../scss/globals';



.no-webp {
    .tmh-life-gallery-1 picture {
       background-image: url("../../../img/life-at-tmh/gallery/gallery-1-min.png");
        @include media(desktop) { background-image: url("../../../img/life-at-tmh/gallery/gallery-1-dk-min.png"); }
    }
    .tmh-life-gallery-2 picture {
        background-image: url("../../../img/life-at-tmh/gallery/gallery-2-min.png");
        @include media(desktop) { background-image: url("../../../img/life-at-tmh/gallery/gallery-2-dk-min.png"); }
    }
}

.webp {
    .tmh-life-gallery-1 picture {
        background-image: url("../../../img/life-at-tmh/gallery/gallery-1-min.webp");
        @include media(desktop) { background-image: url("../../../img/life-at-tmh/gallery/gallery-1-dk.webp"); }
    }

    .tmh-life-gallery-2 picture {
        background-image: url("../../../img/life-at-tmh/gallery/gallery-2-min.webp");
        @include media(desktop) { background-image: url("../../../img/life-at-tmh/gallery/gallery-2-dk.webp"); }
    }
}


.tmh-life {
    overflow: hidden;
    position: relative;
    padding: 88px 0 0;
    background-color: $bg-yellow;
    &:before {
        content: '';
        background-image: url("../../../img/life-at-tmh/shapes/round-shape-1.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 171px;
        height: 185px;
        position: absolute;
        top: -116px;
        left: -46px;
        @include media(screen) {
            width: 281px;
            height: 305px;
        }
    }

    &-header,
    &-wrapper {
        padding: 0 $s-l;
        max-width: 730px;
        margin: 0 auto;
        position: relative;
        @include media(smartphone) {
            padding: 0 $s-xxl;
        }
        @include media(tablet) {
            margin: inherit;
        }
        @include media(desktop) {
            margin: 0 auto;
        }
        @include media(screen) {
            padding: 0;
        }
    }

    &-wrapper {

        &:nth-child(3) {
            @include media(desktop) {
                &:before {
                    content: '';
                    width: 283px;
                    height: 230px;
                    background-image: url("../../../img/life-at-tmh/shapes/round-shape-2.svg");
                    position: absolute;
                    right: -65%;
                    top: -45%;
                }
            }
        }

        &:nth-child(5) {
            @include media(desktop) {
                &:before {
                    content: '';
                    width: 168px;
                    height: 94px;
                    background-image: url("../../../img/life-at-tmh/shapes/round-shape-3.svg");
                    position: absolute;
                    left: -40%;
                    top: 25%;
                }
            }
        }

        &:nth-child(6) {
            margin: 0 0 108px;
            @include media(desktop) {
                margin: 0 auto 108px;
            }

            .title {
                font-family: $f-text;
                font-size: 20px;
                font-weight: 400;
                line-height: 1.5;
                @include media(desktop) {
                    font-size: 28px;
                }
            }
        }

    }

    &-gallery-1,
    &-gallery-2 {
        height: 400px;
        max-width: 1375px;
        margin: 64px auto $s-xxl;
        position: relative;
        @include media(tablet) {
            height: 600px;
            background-size: auto;
            margin: 150px auto 128px;
        }

        picture {
            max-width: 1275px;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 60% 0;
            position: relative;
            z-index: 3;
            @include media(desktop) {
                background-position: center;
            }
        }
    }

    &-gallery-1 {

        @include media(tablet) {
            &:after {
                content: '';
                height: 340px;
                width: 325px;
                background-color: rgba(0, 219, 245, 0.2);
                position: absolute;
                left: -8%;
                top: -80px;
                z-index: 1;
            }
        }

        span {
            @include media(tablet) {
                height: 319px;
                width: 319px;
                background-image: linear-gradient(4deg, $turquoise 6%, $turquoise-medium 97%);
                transform: skewY(2deg);
                position: absolute;
                bottom: -80px;
                right: -8%;
                z-index: 1;
            }
        }
    }

    &-gallery-2 {
        height: 370px;
        margin: 64px auto 0;
        @include media(tablet) {
            height: 600px;
            margin: 150px auto 128px;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: -3%;
                width: 456px;
                height: 387px;
                border-radius: 50px;
                box-shadow: 0 80px 60px 0 rgba(24, 183, 189, 0.2);
                z-index: 1;
            }
        }

        @include media(screen) {
            &:before {
                left: 10%;
            }
        }
    }

}