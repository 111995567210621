@import '../../../../scss/globals';

.entry {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    display: block;
    margin-bottom: $s-s;
    @include media(tablet) {
        font-size: 16px;
    }

    &.orange {color: $orange-medium;}
    &.blue {color: $blue;}
    &.yellow {color: $yellow-medium;}
    &.green {color: $green-light;}
}