@import '../../../../../scss/globals';
@import '../../../../../scss/tools/gradients';
@import '../../../../../scss/tools/animations';

.navigation {
    font-family: $f-title;
    width: 100%;
    @include bg-gradient(light-blue);
    height: 100vh;
    overflow-y: auto;
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    @include media(tablet) {
        width: 372px;
        height: 480px;
        top: 80px;
        right: 28px;
        border-radius: 10px;
        background-color: $white;
        background-image: none;
        @include box-shadow(medium);
        overflow-y: unset;
    }

    @include media(screen) {
        position: relative;
        display: block;
        background-image: none;
        height: auto;
        width: calc(100% - 195px);
        margin-left: 20px;
        padding-top: 5px;
        top: unset;
        right: unset;
        box-shadow: none;
    }

    &.show {
        display: block;
        @include slide-in-right;

        @include media(tablet) {
            .navigation-shadow {
                position: absolute;
                width: 100vw;
                height: 100vh;
                top: -80px;
                right: -40px;
            }
        }
    }

    &.hide {
        display: block;
        @include slide-out-right;
        @include media(screen) {
            animation: none;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $s-l;
        @include media(tablet) {display: none;}
    }

    .close-button {
        height: 16px;
    }

    &-title {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        padding-left: 37px;
        @include media(tablet) { padding-left: 42px; }
        @include media(screen) {display: none;}
    }

    .lang-selector {
        padding: $s-l 0 0 37px;
        @include media(tablet) {
            padding: $s-l 0 0 42px;
        }
        @include media(screen) {
            padding: 0;
            position: absolute;
            top: 20px;
            right: 184px;
        }

        @include media(screen) {
            right: 193px;
        }

        &.text {
            @include media(screen) {display: none;}
        }

        &.flag {
            display: none;
            @include media(screen) {display: block;}
        }

    }

}