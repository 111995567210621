@import '../../../../scss/globals';


.join-us {
    padding: 148px $s-l 188px;
    width: 100%;
    position: relative;
    @include media(screen) {padding: 174px $s-l 222px;}

    &.light {
        background-size: cover;
        background-repeat: no-repeat;
        @include bg-gradient(electric-blue);
        background-image: url("../../../../img/blocks/join-us/dots.png");
        @include media(screen) {
            background-image: url("../../../../img/blocks/join-us/dots-dk.png");
        }
    }

    &.dark {
        background-size: cover;
        background-image: url("../../../../img/blocks/join-us/join-us-backnd-mobile.png");
        @include media(screen) {
            background-image: url("../../../../img/blocks/join-us/join-us-backnd.jpg");
        }
    }

    &:before {
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        width: 313px;
        height: 309px;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
        left: 0;
        @include media(screen) {
            width: 449px;
            height: 613px;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        text-align: center;
        max-width: 530px;
        margin: 0 auto;
    }

    h4 {
        font-size: 30px;
        line-height: 36px;
        font-family: $f-title;
        color: $white;
        margin: 0 auto $s-m;
        @include media(tablet) {
            text-align: center;
            max-width: 65%;
        }
        @include media(desktop) {
            font-size: 44px;
            line-height: 64px;
            max-width: 100%;
        }
    }

    p {
        font-size: 18px;
        color: rgba($white, .8);
        line-height: 29px;
        letter-spacing: -0.1px;
        margin: 0 auto $s-l;
        @include media(tablet) {
            max-width: 65%;
        }
        @include media(desktop) {
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 32px;
            max-width: 100%;
        }
    }

    a.button {
        line-height: 3.6;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media(desktop) {
            line-height: 2.8;
        }
    }
}