@import "./globals";
@import "./tools/helpers";

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

input,
textarea,
select,
button {
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
}

input[type="text"],
textarea {
    width: 100%;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    &:active {transform: translateY(2px);}
    &:focus {outline: none;}
}

picture {
    display: block;
}

img {
    object-fit: cover;
}

a {
    color: $text;
    text-decoration: none;
    display: inline-block;
}


body {
    font-size: 16px;
    font-family: $f-text;
    color: $text;
    font-weight: 400;
    background-color: $bg-blue;
    border-bottom: 8px solid $blue-border;
    padding-top: 80px;
    @include media(tablet) {
        padding-top: 114px;
        border-bottom: 24px solid $blue-border;
    }
    ::selection {
        background: $bg-green;
    }
    ::-moz-selection {
        background: $bg-green;
    }
}


