@import '../../../../scss/globals';

.Header {
    background-color: $white;
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    @include box-shadow(light);
    @include media(tablet) {
        height: 114px;
    }

    .wrapper {
        height: 100%;
        display: grid;
        justify-content: start;
        align-items: center;
        grid-template-columns: auto auto 1fr;
        padding: 6px 20px;
        @include media(screen) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 32px;
        }
    }

    &-logo {
        height: 30px;       
        @include media(screen-wide) {height: 40px;}
        img {height: 100%;}
    }

    .GPTW {      
        margin: 0 18px;  
        img { 
            object-fit: contain;            
        }
        @include media(tablet) { 
            margin-left: 24px;
            img { min-width: 50px; }
        }
    }

    .hamburguer {
        width: 18px;
        height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-self: end;
        &.hide {display: none;}
        @include media(tablet) {
            cursor: pointer;
            width: 24px;
            height: 21px;    
        }
        @include media(desktop) { justify-self: end; }
        @include media(screen) { display: none; }

        span {
            height: 2px;
            width: 100%;
            display: block;
            background-color: $text;
            &:last-child {width: 50%;}
            @include media(tablet) {
                height: 3px;
                border-radius: 1px;
            }
        }
    }

    .close-button {
        width: 20px;
        height: 20px;
        cursor: pointer;
        &.hide {display: none;}
        @include media(screen) {
            display: none;
        }
    }
}