@import '../../../../../scss/globals';

.country {
    padding-left: $s-l;
    position: relative;
    display: flex;
    align-items: center;
    @include media(smartphone) {
        padding-left: 38px;
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(7) {
        margin-bottom: $s-m;
        @include media(screen) {
            margin-bottom: 26px;
        }
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(8),
    &:nth-child(9) {
        margin-bottom: $s-xxl;
        @include media(screen) {
            margin-bottom: 67px;
        }
    }

    &:nth-child(10) {
        margin-bottom: 57px;
        @include media(screen) {
            margin-bottom: 90px;
        }
    }

    span {
        display: inline-block;
        text-align: right;
        width: 40px;
        position: absolute;
        top: 4px;
        left: -48px;
        color: rgba($text, .6);
        @include media(screen) {
            top: 2px;
            width: 65px;
            left: -80px;
        }
    }

    p {
        font-weight: 600;
        @include media(screen) {
            float: left;
            margin-right: $s-s;
        }
    }

    a {
        text-decoration: underline;
        text-decoration-color: $blue;
        @include media(screen) {
            float: right;
        }
    }

    img {
        margin-right: $s-s;
        box-shadow: 0 4px 8px 0 rgba(10, 20, 74, 0.2);
        background-color: $white;
        border: solid 2px $white;
        border-radius: 4px;
    }


    // Gradients
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(10) {
        &:before {
            content: '';
            display: block;
            width: 3px;
            position: absolute;
            left: 4px;
            top: 4px;
            @include media(screen) {
                width: 4.5px;
            }
        }

        // Dot
        &:after {
            content: '';
            position: absolute;
            left: -1px;
            top: 6px;
            z-index: 2;
            @include dot();
            @include media(screen) {
                left: -4px;
            }
        }
    }


    &:nth-child(2) {
        &:before {
            height: 150px;
            background-image: linear-gradient($blue 50%, $orange-medium 100%);
        }
    }

    &:nth-child(4) {
        &:before {
            height: 150px;
            background-image: linear-gradient($orange-medium 50%, $yellow-dark 100%);
        }
    }

    &:nth-child(6) {
        &:before {
            height: 100px;
            background-image: linear-gradient($yellow-dark 50%, $yellow-light 100%);
        }
    }

    &:nth-child(7) {
        &:before {
            height: 150px;
            background-image: linear-gradient($yellow-light 50%, $turquoise 100%);
        }
    }

    &:nth-child(9) {
        &:before {
            height: 100px;
            background-image: linear-gradient($turquoise 50%, $turquoise-light 100%);
        }
    }
}