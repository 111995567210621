.cta {
    color: $white;
    border-radius: 4px;
    padding: $s-ss $s-xl;
    letter-spacing: -0.32px;
    @include box-shadow(light);
    @include bg-gradient(orange);
    &:hover {
        background-image: none;
        background-color: #ff9769;
    }
    &:active,
    &:focus {
        font-weight: 500;
        background-image: none;
        background-color: $orange-medium;
    }
}