@import '../../../../../scss/globals';

.tag {
    font-size: 13px;
    color: $white;
    padding: $s-ss 24px $s-ss 14px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: all .3s;
    box-shadow: 0 5px 10px 0 rgba(10, 20, 74, 0.05);
    @include media(smartphone) {
        font-size: 16px;
        padding: $s-m 32px 15px $s-l;
    }

    @include media(screen) {
        font-size: 22px;
        padding: $s-m 40px $s-m $s-l;
    }

    &.blue {background-image: linear-gradient(271deg, $blue 100%, #0055ff 3%);}
    &.yellow {background-image: linear-gradient(to left, #fdc000 100%, #fcd43d 3%);}
    &.green {background-image: linear-gradient(to left, #67e8b5, #00ba81);}
    &.coral {background-image: linear-gradient(to left, $orange-medium, #ff9769);}
    &.orange {background-image: linear-gradient(to left, #ff8b00, #ef6a02);}
    &.lime {background-image: linear-gradient(to left, #83ce00, #a1e81b);}
    &.red {background-image: linear-gradient(to right, #fd7184, #f73c5b);}
    &.purple {background-image: linear-gradient(to left, #4747ff 100%, #6261f5 3%);}
    &.green, &.red {
        @include media(tablet) {margin: 0;}
    }
    &.orange {
        @include media(tablet) {
            margin-left: 0;
        }
    }



    &.yellow,
    &.orange, {
        @include media(tablet) {
            margin-top: 0;
        }
    }

    img {
        margin-right: 4px;
        @media (min-width: 375px) {
            margin-right: $s-s;
        }

        @include media(screen){
            height: 32px;
        }
    }
}