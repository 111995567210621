@import '../../../../scss/globals';

.careers {
    padding: $s-l $s-s 130px;
    background-color: $bg-blue-light;
    position: relative;
    z-index: 1;
    @include media(smartphone) {
        padding: $s-l $s-xl 130px;
    }
    @include media(screen) {
        padding: 0 0 295px;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        z-index: 2;
    }

    &:before {
        left: 0;
        top: 0;
        width: 180px;
        height: 201px;
        background-image: url("../../../../img/blocks/careers/rectangle-shape.svg");
        background-size: cover;
        @include media(screen) {
            width: 248px;
            height: 170px;
            top: -53px;
            left: 8%;
        }
    }

    &:after {
        right: -10px;
        bottom: 68px;
        width: 180px;
        height: 187px;
        background-image: url("../../../../img/blocks/careers/round-shape.svg");
        background-size: cover;
        @include media(screen) {
            width: 477px;
            height: 388px;
            top: 5px;
            right: -5%;
        }
    }

    ul {
        max-width: 600px;
        margin: 0 auto;
        @include media(desktop) {
            max-width: 1110px;
            display: flex;
        }

        li {
            @include media(desktop) {
                width: 33%;
                height: 195px;
            }

            &:nth-child(2) {
                @include media(desktop) {margin: 0 30px;}
            }

        }
    }
}